<template>
  <div>
    <load-profile></load-profile>
    <section id="dashboard-analytics">
      <b-row class="match-height">
        <b-col cols="12" md="6" lg="6" xl="3">
          <StatisticCardHorizontal
            :loading="loading"
            :statistic="data.totalCompany"
            :title="$t('field.company')"
            resource="company"
            icon="LayersIcon"
          />
        </b-col>
        <b-col cols="12" md="6" lg="6" xl="3">
          <StatisticCardHorizontal
            :loading="loading"
            :statistic="data.totalDepartment"
            :title="$t('field.department')"
            resource="department"
            icon="GitBranchIcon"
          />
        </b-col>
        <b-col cols="12" md="6" lg="6" xl="3">
          <StatisticCardHorizontal
            :loading="loading"
            :statistic="data.totalPosition"
            :title="$t('field.position')"
            resource="position"
            icon="GitCommitIcon"
          />
        </b-col>
        <b-col cols="12" md="6" lg="6" xl="3">
          <StatisticCardHorizontal
            :loading="loading"
            :statistic="data.totalEmployee"
            :title="$t('field.employee')"
            resource="employee"
            icon="UsersIcon"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col md="12" lg="6">
          <okr-setting-bar-chart></okr-setting-bar-chart>
        </b-col>
        <b-col md="12" lg="6">
          <mid-year-review-bar-chart></mid-year-review-bar-chart>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col cols="12">
          <year-end-review-bar-chart></year-end-review-bar-chart>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import StatisticCardHorizontal from "./components/StatisticCardHorizontal.vue";
import OkrSettingBarChart from "./components/OkrSettingBarChart.vue";
import MidYearReviewBarChart from "./components/MidYearReviewBarChart.vue";
import YearEndReviewBarChart from "./components/YearEndReviewBarChart.vue";
import Repository from "@/repositories/RepositoryFactory";

const DashboardRepository = Repository.get("dashboard");

export default {
  components: {
    BRow,
    BCol,

    StatisticCardHorizontal,
    OkrSettingBarChart,
    MidYearReviewBarChart,
    YearEndReviewBarChart,
  },
  data() {
    return {
      loading: false,
      data: {
        totalCompany: 0,
        totalDepartment: 0,
        totalPosition: 0,
        totalEmployee: 0,
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.index();
  },
  methods: {
    index() {
      this.loading = true;
      DashboardRepository.summary()
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data.totalCompany = data.totalCompany;
            this.data.totalDepartment = data.totalDepartment;
            this.data.totalPosition = data.totalPosition;
            this.data.totalEmployee = data.totalEmployee;
          }
        })
        .catch((error) => {
          this.loading = false;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
