<template>
  <b-card no-body>
    <b-overlay :show="loading" rounded="sm" variant="light">
      <b-card-header>
        <b-card-title>{{ $t("breadcrumb.okrSetting") }}</b-card-title>
        <n-year-picker key="yearPicker" v-model="yearPicker" />
      </b-card-header>

      <b-card-body>
        <chartjs-component-bar-chart
          :key="`okr-setting-chart-${key}`"
          :height="400"
          :data="chartjsData.okrSettingBarChart.data"
          :options="chartjsData.okrSettingBarChart.options"
        />
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BOverlay,
} from "bootstrap-vue";
import NYearPicker from "@/components/NYearPicker.vue";
import ChartjsComponentBarChart from "@/components/charts-components/ChartjsComponentBarChart.vue";
import moment from "moment";
import chartjsData from "@/views/dashboard/chartjsData";
import Repository from "@/repositories/RepositoryFactory";

const DashboardRepository = Repository.get("dashboard");

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BOverlay,

    NYearPicker,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      chartjsData,
      yearPicker: null,
      chartData: {},
      key: 1,
      loading: false,
      query: {
        year: this.$route.query.year || null,
      },
    };
  },
  created() {
    var now = moment().format("Y");
    this.yearPicker = now;
  },
  watch: {
    yearPicker: function (val) {
      this.query.year = val;
      this.updateQuerySting();
      this.index();
    },
  },
  mounted() {
    this.index();
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    index() {
      this.loading = true;
      const query = {
        ...this.query,
      };
      DashboardRepository.chart(query)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.chartData = data;
            this.chartjsData.okrSettingBarChart.data.labels = [...data.labels];
            this.chartjsData.okrSettingBarChart.data.datasets[0].data = [
              ...data.okrSettingData,
            ];
            this.chartjsData.okrSettingBarChart.options.scales.yAxes[0].ticks.max =
              data.totalEmployee;
            this.chartjsData.okrSettingBarChart.data.datasets[0].label =
              this.$t("breadcrumb.okrSetting");
            this.key++;
          }
        })
        .catch((error) => {
          this.loading = false;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
