<template>
  <b-card no-body>
    <b-overlay :show="loading" rounded="sm" variant="light">
      <b-card-header>
        <b-card-title>{{ $t("breadcrumb.yearEndReview") }}</b-card-title>
        <n-year-picker key="yearPicker" v-model="yearPicker" />
      </b-card-header>

      <b-card-body>
        <chartjs-component-bar-chart
          :key="`year-end-review-chart-${key}`"
          :height="400"
          :data="chartjsData.yearEndReviewBarChart.data"
          :options="chartjsData.yearEndReviewBarChart.options"
        />
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BOverlay,
} from "bootstrap-vue";
import NYearPicker from "@/components/NYearPicker.vue";
import ChartjsComponentBarChart from "@/components/charts-components/ChartjsComponentBarChart.vue";
import moment from "moment";
import chartjsData from "@/views/dashboard/chartjsData";
import Repository from "@/repositories/RepositoryFactory";

const DashboardRepository = Repository.get("dashboard");

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BOverlay,

    NYearPicker,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      chartjsData,
      yearPicker: null,
      chartData: {},
      key: 1,
      loading: false,
      query: {
        yearEndReviewYear: this.$route.query.yearEndReviewYear || null,
      },
    };
  },
  created() {
    var now = moment().format("Y");
    this.yearPicker = now;
  },
  watch: {
    yearPicker: function (val) {
      this.query.yearEndReviewYear = val;
      this.updateQuerySting();
      this.index();
    },
  },
  mounted() {
    this.index();
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    index() {
      this.loading = true;
      const params = {
        ...this.query,
      };
      DashboardRepository.chart(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.chartData = data;
            this.chartjsData.yearEndReviewBarChart.data.labels = [
              ...data.yearEndReviewLabels,
            ];
            this.chartjsData.yearEndReviewBarChart.data.datasets[0].data = [
              ...data.yearEndReviewData,
            ];
            this.chartjsData.yearEndReviewBarChart.options.scales.yAxes[0].ticks.max =
              data.totalEmployee;
            this.chartjsData.yearEndReviewBarChart.data.datasets[0].label =
              this.$t("breadcrumb.yearEndReview");
            this.key++;
          }
        })
        .catch((error) => {
          this.loading = false;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>
